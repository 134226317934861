/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { Seguimiento } from '../model/models';
import { SeguimientoRequestBody } from '../model/models';
import { TokenActivacion } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AnadirSeguimientoRequestParams {
    xUUID: string;
    /** configuración del seguimiento */
    seguimientoRequestBody: SeguimientoRequestBody;
}

export interface EliminarSeguimientoRequestParams {
    xUUID: string;
    /** ID único del Seguimiento */
    seguimientoID: number;
}

export interface ObtenerSeguimientoBlisterRequestParams {
    xUUID: string;
    /** Token único para el blister */
    blisterToken: string;
}

export interface ObtenerTodosSeguimientosRequestParams {
    xUUID: string;
}

export interface ObtenerTokenActivacionRequestParams {
    xUUID: string;
    /** ID único del Seguimiento */
    seguimientoID: number;
}


@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

    protected basePath = 'https://virtserver.swaggerhub.com/ovicenteabatura/CircuPack/0.0.1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Añade un seguimiento
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public anadirSeguimiento(requestParameters: AnadirSeguimientoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Seguimiento>;
    public anadirSeguimiento(requestParameters: AnadirSeguimientoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Seguimiento>>;
    public anadirSeguimiento(requestParameters: AnadirSeguimientoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Seguimiento>>;
    public anadirSeguimiento(requestParameters: AnadirSeguimientoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xUUID = requestParameters.xUUID;
        if (xUUID === null || xUUID === undefined) {
            throw new Error('Required parameter xUUID was null or undefined when calling anadirSeguimiento.');
        }
        const seguimientoRequestBody = requestParameters.seguimientoRequestBody;
        if (seguimientoRequestBody === null || seguimientoRequestBody === undefined) {
            throw new Error('Required parameter seguimientoRequestBody was null or undefined when calling anadirSeguimiento.');
        }

        let headers = this.defaultHeaders;
        if (xUUID !== undefined && xUUID !== null) {
            headers = headers.set('X-UUID', String(xUUID));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Seguimiento>(`${this.configuration.basePath}/seguimiento`,
            seguimientoRequestBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Elimina un seguimiento para el dispositivo identificado por UUID
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public eliminarSeguimiento(requestParameters: EliminarSeguimientoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public eliminarSeguimiento(requestParameters: EliminarSeguimientoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public eliminarSeguimiento(requestParameters: EliminarSeguimientoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public eliminarSeguimiento(requestParameters: EliminarSeguimientoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xUUID = requestParameters.xUUID;
        if (xUUID === null || xUUID === undefined) {
            throw new Error('Required parameter xUUID was null or undefined when calling eliminarSeguimiento.');
        }
        const seguimientoID = requestParameters.seguimientoID;
        if (seguimientoID === null || seguimientoID === undefined) {
            throw new Error('Required parameter seguimientoID was null or undefined when calling eliminarSeguimiento.');
        }

        let headers = this.defaultHeaders;
        if (xUUID !== undefined && xUUID !== null) {
            headers = headers.set('X-UUID', String(xUUID));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/seguimiento/${encodeURIComponent(String(seguimientoID))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve el seguimiento configurado para el solicitante para un blister identificandolo con un token del mismo
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obtenerSeguimientoBlister(requestParameters: ObtenerSeguimientoBlisterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Seguimiento>;
    public obtenerSeguimientoBlister(requestParameters: ObtenerSeguimientoBlisterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Seguimiento>>;
    public obtenerSeguimientoBlister(requestParameters: ObtenerSeguimientoBlisterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Seguimiento>>;
    public obtenerSeguimientoBlister(requestParameters: ObtenerSeguimientoBlisterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xUUID = requestParameters.xUUID;
        if (xUUID === null || xUUID === undefined) {
            throw new Error('Required parameter xUUID was null or undefined when calling obtenerSeguimientoBlister.');
        }
        const blisterToken = requestParameters.blisterToken;
        if (blisterToken === null || blisterToken === undefined) {
            throw new Error('Required parameter blisterToken was null or undefined when calling obtenerSeguimientoBlister.');
        }

        let headers = this.defaultHeaders;
        if (xUUID !== undefined && xUUID !== null) {
            headers = headers.set('X-UUID', String(xUUID));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Seguimiento>(`${this.configuration.basePath}/seguimiento/blister/${encodeURIComponent(String(blisterToken))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve los seguimientos configurados para el solicitante
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obtenerTodosSeguimientos(requestParameters: ObtenerTodosSeguimientosRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Seguimiento>>;
    public obtenerTodosSeguimientos(requestParameters: ObtenerTodosSeguimientosRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Seguimiento>>>;
    public obtenerTodosSeguimientos(requestParameters: ObtenerTodosSeguimientosRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Seguimiento>>>;
    public obtenerTodosSeguimientos(requestParameters: ObtenerTodosSeguimientosRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xUUID = requestParameters.xUUID;
        if (xUUID === null || xUUID === undefined) {
            throw new Error('Required parameter xUUID was null or undefined when calling obtenerTodosSeguimientos.');
        }

        let headers = this.defaultHeaders;
        if (xUUID !== undefined && xUUID !== null) {
            headers = headers.set('X-UUID', String(xUUID));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Seguimiento>>(`${this.configuration.basePath}/seguimiento`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Devuelve un token de activación temporal de seguimiento para enviar al servidor
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obtenerTokenActivacion(requestParameters: ObtenerTokenActivacionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TokenActivacion>;
    public obtenerTokenActivacion(requestParameters: ObtenerTokenActivacionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TokenActivacion>>;
    public obtenerTokenActivacion(requestParameters: ObtenerTokenActivacionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TokenActivacion>>;
    public obtenerTokenActivacion(requestParameters: ObtenerTokenActivacionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xUUID = requestParameters.xUUID;
        if (xUUID === null || xUUID === undefined) {
            throw new Error('Required parameter xUUID was null or undefined when calling obtenerTokenActivacion.');
        }
        const seguimientoID = requestParameters.seguimientoID;
        if (seguimientoID === null || seguimientoID === undefined) {
            throw new Error('Required parameter seguimientoID was null or undefined when calling obtenerTokenActivacion.');
        }

        let headers = this.defaultHeaders;
        if (xUUID !== undefined && xUUID !== null) {
            headers = headers.set('X-UUID', String(xUUID));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TokenActivacion>(`${this.configuration.basePath}/seguimiento/blister/${encodeURIComponent(String(seguimientoID))}/activacion`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
