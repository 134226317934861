import { Injectable } from '@angular/core';
import { environment } from '@env';

export enum StorageKey {
  SELECTED_LANG = 'LANG',
  SELECTED_LOCALE = 'LOCALE',
  DARK_MODE = 'DARK_MODE',
  UUID = 'UUID',
}

@Injectable({ providedIn: 'root' })
export class StorageService {

  private prefix: string = environment.slug.toUpperCase();

  constructor() { }

  public set(key: string, value: any) {
    return localStorage.setItem(`${this.prefix}_${key}`, JSON.stringify(value));
  }

  /** Get a value from storage, try to parse from JSON: If fails return as string, else return as object */
  public get(key: string): any {
    let value = localStorage.getItem(`${this.prefix}_${key}`);
    try {
      value = JSON.parse(value);
    } catch (error) { }
    return value;
  }

  /** Remove a value by key from storage */
  public remove(key: string) {
    return localStorage.removeItem(`${this.prefix}_${key}`);
  }

  /** Clear all storaged values of the app (by prefix) */
  public clearAppItems() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(this.prefix)) {
        console.log(`${key}: ${localStorage.getItem(key)}`);
        localStorage.removeItem(key);
      }
    }
  }

}
