/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Token de activación de seguimiento
 */
export interface TokenActivacion { 
    token?: string;
}

