/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Posibles tipos de seguimiento que se le hacen a un paciente
 */
export type TipoSeguimiento = 'TOMA' | 'SEMANAL';

export const TipoSeguimiento = {
    Toma: 'TOMA' as TipoSeguimiento,
    Semanal: 'SEMANAL' as TipoSeguimiento
};

