import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { AngularMaterialModule } from '@shared/angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiModule, Configuration } from './api';
import { environment } from '@env';
import { DialogComponent } from '@shared/components/dialog/dialog.component';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [DialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IonicModule.forRoot({
      hardwareBackButton: true,
      // mode: 'md',                    // Estilo general del diseño de los componentes: 'md' | 'ios'
      // backButtonText: '',            // Texto junto al ion-back-button (sólo iOS)
      // backButtonIcon: 'arrow-back',  // Icono el ion-back-button general
      sanitizerEnabled: true,
    }),
    CoreModule,
    AngularMaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ApiModule.forRoot(() => {
      return new Configuration({
        basePath: (window as any).EXTERNAL_CONFIG?.API_URL || environment.baseUrl,
        // apiKeys: { 'X-UUID': 'b.d34f997-5cea-4a82-a636-73b4f66a5161' }
      })
    }),
  ],
  providers: [
    // Carga la variaable BASE_URL para el HttpInterceptor de las variables de entorno
    { provide: 'BASE_URL', useValue: (window as any).EXTERNAL_CONFIG?.API_URL || environment.baseUrl },
    // { provide: 'UUID', useClass: DeviceService, useValue: DeviceService },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
