import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  /* {
    path: '',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomePageModule)
  }, */
  {
    path: 'blister',
    loadChildren: () => import('./features/blister/blister.module').then(m => m.BlisterPageModule)
  },
  {
    path: 'install',
    loadChildren: () => import('./features/install/install.module').then(m => m.InstallPageModule)
  },
  {
    path: '',
    redirectTo: 'blister',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
