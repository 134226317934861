import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { StorageKey, StorageService } from './storage/storage.service';

// STATUS-BAR
// import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';

/** Service to manage theme: Light & Dark modes */
@Injectable({ providedIn: 'root' })
export class ThemeService {

  public theme: string = 'light' || 'dark';

  public darkMode: boolean = false;
  private _darkMode: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public darkMode$: Observable<boolean>;

  private enableDarkMode: boolean = environment.hasDarkMode;

  constructor(
    private storage: StorageService,
    private overlayContainer: OverlayContainer,
  ) { }

  /**
   * Init app theme based on browser prefers-color-scheme
   * @param store (default = true) store theme as user preference
   */
  public initTheme(store: boolean = false) {
    if (!this.enableDarkMode) {
      this.toggleTheme(this.enableDarkMode);
    } else {
      // Check if it's dark mode preference for the user
      let darkModePreference: boolean = this.storage.get(StorageKey.DARK_MODE);
      if (darkModePreference === null || darkModePreference === undefined) {
        darkModePreference = this.checkDarkMode();
        // Save as user preference if store === true
        store && this.storage.set(StorageKey.DARK_MODE, darkModePreference)
      }
      // Usamos el pipe `shareReplay(1)` para que comparta el último valor emitido a los nuevos subscriptores
      // (los que se han suscrito al Observable después de su última emisión)
      this.darkMode$ = this._darkMode.asObservable().pipe(shareReplay(1));
      this.toggleTheme(darkModePreference, store);
    }
  }

  /** Return if browser `prefers-color-scheme` is dark */
  private checkDarkMode(): boolean {
    const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    /* StatusBar.setStyle({
      style: isDarkTheme ? StatusBarStyle.Dark : StatusBarStyle.Light
    }); */
    return isDarkTheme;
  }

  /**
   * Toggle between light/dark themes, save as user-preferece and emit the new value
   * @param changeToDarkTheme If TRUE -> apply Dark Mode, otherwise don't
   * @param store (DEFAULT FALSE) Set to TRUE if user change manually or any other reason to persist locally
   */
  public toggleTheme(enableDarkMode: boolean, store: boolean = false): void {
    if (enableDarkMode === null || enableDarkMode === undefined) { return; }
    // Toogle Ionic Dark Theme
    document.body.classList.toggle('dark', enableDarkMode);
    // Toogle Angular Material Dark Theme
    this.changeAngularMaterialTheme(enableDarkMode);
    // Save as user preference if store === true
    store && this.storage.set(StorageKey.DARK_MODE, enableDarkMode);
    this.theme = enableDarkMode ? 'dark' : 'light';
    this.darkMode = enableDarkMode;
    this._darkMode.next(enableDarkMode);
  }

  private changeAngularMaterialTheme(toggleDarkMode: boolean) {
    document.body.classList.toggle('theme-alternate', toggleDarkMode);

    // remove old theme class and add new theme class
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses)
      .filter((item: string) => item.includes('theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    const mdTheme = toggleDarkMode ? 'theme-alternate' : 'theme';  // Defined at material.override.scss
    overlayContainerClasses.add(mdTheme);
    // document.body.classList.toggle('theme-alternate', toggleDarkMode);
  }
}
