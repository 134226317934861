/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Posibles turnos en un día
 */
export type Turno = 'DESAYUNO' | 'COMIDA' | 'CENA' | 'NOCHE';

export const Turno = {
    Desayuno: 'DESAYUNO' as Turno,
    Comida: 'COMIDA' as Turno,
    Cena: 'CENA' as Turno,
    Noche: 'NOCHE' as Turno
};

