import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageKey, StorageService } from '../storage/storage.service';
import { BatteryInfo, Device, DeviceInfo } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class DeviceService {

  /**
   * Universal Unique IDentintifier
   *
   * _example: "bd34f997-5cea-4a82-a636-73b4f66a5161"_
   */
  private _deviceUUID: string;
  public get deviceUUID(): string {
    return this._deviceUUID;
  }
  public set deviceUUID(uuid: string) {
    this._deviceUUID = uuid;
  }

  constructor(
    private platform: Platform,
    private storage: StorageService,
  ) {
    this.initDeviceUUID();
  }

  /** Check localStorage for UUID, if it's not found -> generate one and store */
  public async initDeviceUUID() {
    let uuid = this.storage.get(StorageKey.UUID);
    if (!uuid) {
      // If doesn't exist previously -> Generate new one and store
      uuid = await this.generateDeviceUUID();
      this.storage.set(StorageKey.UUID, uuid);
    }
    this.deviceUUID = uuid;
    return this.deviceUUID;
  }

  /**
   * Get device info
   *
   * @example
   * { isVirtual: false,
   *    manufacturer: "Google Inc.",
   *    model: "Moto G (4)",
   *    operatingSystem: "android",
   *    osVersion: "Android 6.0.1",
   *    platform: "web",
   *    webViewVersion: "94.0.4606.61"
   * }
   */
  private async getDeviceInfo(): Promise<DeviceInfo> {
    const deviceInfo = await Device.getInfo();
    // console.log(deviceInfo);
    return deviceInfo;
  }

  /**
   * @returns current battery info
   *
   * @example { batteryLevel: 1, isCharging: true }
   */
  private async getDeviceBatteryInfo(): Promise<BatteryInfo> {
    const batteryInfo = await Device.getBatteryInfo();
    // console.log(batteryInfo);
    return batteryInfo;
  }

  /**
   * Get the device's Universally Unique Identifier (UUID).
   *
   * The details of how a UUID is generated are determined by the device manufacturer and are specific to the device's platform or model.
   *
   * Example: { uuid: "bd34f997-5cea-4a82-a636-73b4f66a5161" }
   *
   * @see IONIC https://github.com/apache/cordova-plugin-device
   * @see CAPACITOR https://capacitorjs.com/docs/apis/device
   * @see CORDOVA https://ionicframework.com/docs/native/device
   */
  private async generateDeviceUUID() {
    const { uuid } = await Device.getId();
    console.log('Device new uuid: ', uuid);
    return uuid;
  }

  public isIOS(): boolean {
    return this.platform.is('ios');
  }

  public isAndroid(): boolean {
    return this.platform.is('android');
  }

  public isCordova(): boolean {
    return this.platform.is('cordova');
  }

  public isDesktop() {
    return this.platform.platforms().includes('desktop');
  }
}
