import {
  Component,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  /** Dialog title */
  title?: string,
  /** Content text */
  text: string,
  /** Action button label */
  button: string,
  /** Close button label */
  closeButton: string,
  /** (Optional) image source */
  image?: string,
  /** Callback for success response */
  onSuccess?: (data: any) => void,
  /** Callback for error response */
  onError?: (error: any) => void,
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

}
