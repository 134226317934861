import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogData } from '@shared/components';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  // Initialize deferredPrompt for use later to show browser install prompt.
  private deferredPrompt: any = null;
  private _installPromptReady: BehaviorSubject<any> = new BehaviorSubject(this.deferredPrompt);
  public readonly installPromptReady$: Observable<any> = this._installPromptReady.asObservable().pipe(shareReplay(1));

  public alreadyInstalled: boolean = false;
  private _installationStatusChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.alreadyInstalled);
  public readonly installationStatusChanged$: Observable<boolean> = this._installationStatusChanged.asObservable();

  constructor(
    private modalService: ModalService,
    private translate: TranslateService,
  ) {
    // this.listenInstallPrompt();
  }

  public listenInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.emitInstallPrompt();
      // Update UI notify the user they can install the PWA
      // this.showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`);
    });

    window.addEventListener('appinstalled', () => {
      this.alreadyInstalled = true;
      this._installationStatusChanged.next(this.alreadyInstalled);
      // Hide the app-provided install promotion
      // hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      this.deferredPrompt = null;
      this.emitInstallPrompt();
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    });

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (isStandalone) {
      this.alreadyInstalled = true;
      this._installationStatusChanged.next(this.alreadyInstalled);
    }
  }

  public showInstallPrompt() {
    if (!this.deferredPrompt) { return; }

    const data: DialogData = {
      image: 'assets/icon/blister.svg',
      title: this.translate.instant('PWA.INSTALL_TITLE'),
      text: this.translate.instant('PWA.INSTALL_TEXT'),
      button: this.translate.instant('BUTTON.INSTALL'),
      closeButton: this.translate.instant('BUTTON.CANCEL'),
    }
    this.modalService.showDialog(data)
      .subscribe(
        // Si responde la opción afirmativa
        (response: boolean) => response && this.installOnDevice(),
        (error: any) => console.error(error)
      )
  }

  private async installOnDevice() {
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await this.deferredPrompt.userChoice;
    console.log(`User response to the install prompt: ${outcome}`);
    this.deferredPrompt = null;
    this.emitInstallPrompt();
  }

  private emitInstallPrompt() {
    this._installPromptReady.next(this.deferredPrompt);
  }
}
