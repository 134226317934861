// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from '../../package.json';

export const environment = {
  production: true,
  // Nombre a mostrar de la app
  appName: 'Medical Dispenser (DEV)',
  // Nombre de la app para ficheros (para evitar conflictos de espacios en blanco, etc.)
  slug: 'md',
  // App version
  version: packageInfo.version,
  // URL base de la API (opcional)
  baseUrl: 'https://md.k8s.gailen.net',
  // Idoma por defecto de la app (opcional)
  defaultLanguage: 'es',
  // Lista de lenguajes disponibles para el multi-idioma
  availableLanguages: ['es', 'en', 'pt', 'it', 'fr'],
  // Tiene activado el Dark Mode?
  hasDarkMode: false,
  // En caso de asociar proyecto de Firebase (opcional)
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
