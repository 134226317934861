import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export enum EModalType {
  ALERT,
  CONFIRM,
  CREATE,
  DELETE,
  COMMENTS,
  UPDATE,
  SUCCESS
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input() public title?: string;
  @Input() public subtitle?: string;
  @Input() public img?: string;
  @Input() public text?: string;
  @Input() public modalType?: EModalType;
  @Input() public lastVersion?: string;

  @Input() public btnOn: string;
  @Input() public btnOff: string;
  public EModalType = EModalType;

  constructor(
    private modal: ModalController,
    private translate: TranslateService,
  ) { }

  public ngOnInit() {
    this.generateModaType();
  }

  public reponse = (hasAccept: boolean) => {
    this.modal.dismiss({ response: hasAccept });
  }

  private generateModaType() {
    switch (this.modalType) {
      case EModalType.ALERT:
        this.btnOn = this.translate.instant('ok');
        this.img = './assets/img/ic_error.png';
        break;

      case EModalType.CONFIRM:
        if (this.btnOn == null) {
          this.btnOn = this.translate.instant('continue');
        }
        /* if (this.btnOff == null) {
          this.btnOff = this.translate.instant('cancel');
        }

        if (this.img == null) {
          this.img = './assets/img/alert.svg';
        } */
        break;

      case EModalType.CREATE:
        this.btnOn = this.translate.instant('create');
        this.btnOff = this.translate.instant('cancel');
        break;

      case EModalType.DELETE:
        this.img = this.img || './assets/img/delete.svg';
        this.btnOn = this.translate.instant('continue');
        this.btnOff = this.translate.instant('cancel');
        break;

      case EModalType.COMMENTS:
        this.title = this.translate.instant('comments');
        this.btnOn = this.translate.instant('add');
        this.btnOff = this.translate.instant('cancel');
        break;

      case EModalType.UPDATE:
        this.title = this.translate.instant('update_app');
        this.text = this.translate.instant('update_text', this.lastVersion);
        this.img = './assets/img/logo.svg';
        this.btnOn = this.translate.instant('update');
        break;

      case EModalType.SUCCESS:
        this.btnOn = this.translate.instant('BUTTON.UNDERSTAND');
        console.log("Img %O", this.img);
        break;

      default:
        break;
    }
  }

}
