import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { LoadingController, ModalController, PickerController } from '@ionic/angular';
import { EModalType, ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subject } from 'rxjs';
// import { PopupComponent } from '@shared/components/popup/popup.component';
import { PickerOptions, PickerColumn, PickerColumnOption } from "@ionic/core";
import { shareReplay } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { DialogComponent, DialogData } from '@shared/components/dialog/dialog.component';

export interface ModalProperties {
  /** Modal subtitle */
  subtitle?: string;
  /** Can close the modal touching offside? */
  backdropDismiss?: boolean;
  /** Can clean the selection */
  showEmptyValue?: boolean;
  /** Modal style, defined as global */
  style?: string;
}

/** MatDialog configurations */
export interface DialogConfig {
  id?: string,
  width?: number,
  height?: number,
  hasBackdrop?: boolean,
  backdropClass?: string | string[],
}

export interface PickerOpts {
  selectButton: string,
  cancelButton: string,
  cssClass?: string | string[]
}

/**
 * Service for UI invading components like:
 * * Modal (IonModal)
 * * Dialog (MatDialog)
 * * Snackbar (MatSnackbar)
 * * BottomSheet (MatBottomSheet)
 */
@Injectable({ providedIn: 'root' })
export class ModalService {

  private _pickerSelection: Subject<any> = new Subject();
  /** Observable that emits value when picker selection in confirm */
  public readonly pickerSelection$: Observable<any> = this._pickerSelection.asObservable().pipe(shareReplay(1));

  /** Variables del loader */
  public loadingElem: HTMLIonLoadingElement;
  private showLoader: boolean = false;
  private readonly LOADING_TIMEOUT = 30000;

  constructor(
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    // private bottomSheet: MatBottomSheet,
    private pickerController: PickerController,
    private snackbar: MatSnackBar,
    private loadingController: LoadingController,
  ) { }

  /** Show Ionic Modal */
  public showModal = async (
    modalType: EModalType,
    title?: string,
    text?: string,
    img?: string,
    subtitle?: string,
    btnOn?: string,
    btnOff?: string,
  ): Promise<any> => {
    const modal = await this.modalCtrl.create({
      component: ModalComponent,
      componentProps: {
        modalType,
        title,
        subtitle,
        img,
        text,
        btnOn,
        btnOff,
      },
      cssClass: 'popup-modal',
    });
    modal.present();
    // Return buttonOn tao as TRUE,
    return modal.onDidDismiss().then(
      (data: any) => {
        if (data && data.data) {
          return data.data.response;
        }
      },
    );
  }

  /**
   * Show Angular Material Dialog with a generic component from @shared
   * @param data data that will inject in `DialogComponent`
   * @param config Dialog configurations
   * @returns `Observable<boolean>` with user response:
   * * true -> if button tap
   * * false -> if close button tap
   * * undefined -> if backdropp dismiss
   */
  public showDialog(data: DialogData, config?: DialogConfig): Observable<boolean> {
    const dialogRef = this.dialog.open(DialogComponent, {
      data,
      id: config?.id,
      width: `${config?.width}px`,
      height: `${config?.height}px`,
      hasBackdrop: config?.hasBackdrop ?? true,
      backdropClass: config?.backdropClass,
    });

    return dialogRef.afterClosed()/* .subscribe(result => {
      console.log(`Dialog result: ${result}`);
      return result;
    }); */
  }

  /**
   * Show Angular Material Snackbar
   * @param message text string to display in snackbar
   * @param buttonLabel button text label
   * @param duration (default 2000ms) the time in ms that display the snackbar in view
   * @returns snackbar reference to handle the posible action button click
   */
  public showSnackbar(message: string, buttonLabel?: string, duration = 2000): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackbar.open(message, buttonLabel, { duration });
  }

  public showBottomSheet() {
    // this.bottomSheet.open(BottomSheetOverviewExampleSheet);
  }

  /**
   * Show bottom sheet column picker
   * @param numColumns number of columns
   * @param numOptions number of options per column
   * @param columnOptions string array of option to each column
   * @return a promise with selected value(s)
   */
  public async showPicker(numColumns = 1, numOptions: number[], columnOptions: PickerColumn[] = [], config?: PickerOpts): Promise<any> {
    let options: PickerOptions = {
      buttons: [
        {
          text: config?.cancelButton ?? 'Cancel',
          role: 'cancel'
        },
        {
          text: config?.selectButton ?? 'Select',
          handler: (value: any) => {
            // console.log(value);
            this._pickerSelection.next(value);
          },
          role: 'confirm'
        }
      ],
      columns: this.getColumns(numColumns, numOptions, columnOptions),
      animated: true,
      cssClass: config?.cssClass,
      mode: 'ios'
    };

    let picker = await this.pickerController.create(options);
    picker.present();
    return picker.onDidDismiss();
  }

  /**
   * Return columns
   * @param numColumns number of columns
   * @param numOptions number of options per column
   * @param columnOptions string array of option to each column
   * @return columns
   */
  private getColumns(numColumns: number, numOptions: number[], columnOptions: PickerColumn[]) {
    let columns = [];
    // Iterate over all columns
    for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
      // And push the options (rows) of each one
      columns.push({
        name: columnOptions[columnIndex]?.name ?? columnIndex, // columnIndex/* `col-${i}` */,
        selectedIndex: columnOptions[columnIndex]?.selectedIndex ?? 0,
        options: this.getColumnOptions(numOptions[columnIndex], columnOptions[columnIndex].options)
        // options: this.getColumnOptions(i, numOptions[i], columnOptions[i].options),
      });
    }
    return columns;
  }

  /**
   * Populate each column with data
   * @param columnIndex
   * @param numOptions
   * @param columnOptions
   * @returns each column option
   */
  private getColumnOptions(numOptions: number, columnOptions: PickerColumnOption[]): any[] {
    let options: any[] = [];
    // Iterate over column options and fill with text (label) and value
    for (let columnOptionIndex = 0; columnOptionIndex < numOptions; columnOptionIndex++) {
      options.push({
        text: columnOptions[columnOptionIndex]/* [i % numOptions] */.text,
        value: columnOptions[columnOptionIndex]/* [i % numOptions] */.value ?? columnOptionIndex
      })
    }
    return options;
  }

  /**
   * Muestra un loading en pantalla
   * @param show `boolean` indicador de si debe mostrarlo y ocultarlo
   * @param message (opcional) mensaje del loader
   */
  public async toogleLoading(show: boolean, message?: string) {
    // If want to show loader and it's not already showing
    if (show && !this.showLoader) {
      this.showLoader = true;
      this.loadingElem = await this.loadingController.create({
        message,
      });
      // Show if in between, state doesn't change to dismiss
      this.showLoader && this.loadingElem.present();
      // Timeout clause
      setTimeout(() => {
        // console.warn("Loading timeout");
        this.dismissLoading();
        // TODO: mostrar snackbar para feedback de timeout
      }, this.LOADING_TIMEOUT);
    } else if (!show && this.showLoader /* && this.loadingElem */) {
      // Hide loader
      this.dismissLoading();
    }
  }

  private dismissLoading() {
    this.loadingElem?.dismiss();
    this.showLoader = false;
    this.loadingElem = null;
  }
}
