import { Injectable, LOCALE_ID } from '@angular/core';
// LOCALE
/* import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localeEu from '@angular/common/locales/eu';
import { registerLocaleData } from '@angular/common'; */

import { ActionSheetController } from '@ionic/angular';
import { StorageKey, StorageService } from '../storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/* const LOCALE = {
  es: localeEs,
  en: localeEn,
  eu: localeEu,
}; */

const MONTHS = {
  es: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEPT', 'OCT', 'NOV', 'DIC'],
  en: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  eu: ['URT', 'OTS', 'MAR', 'API', 'MAI', 'EKA', 'UZT', 'ABU', 'IRA', 'URR', 'AZA', 'ABE'],
};

@Injectable({ providedIn: 'root' })
export class LanguageService {

  public browserLang: string;
  public defaultLanguage = environment.defaultLanguage;

  private _lang: BehaviorSubject<string> = new BehaviorSubject(this.defaultLanguage);
  public readonly lang$: Observable<string> = this._lang.asObservable().pipe(shareReplay(1));

  private _language: string;
  public get language(): string {
    // const storedLang = this.storage.get(StorageKey.SELECTED_LANG) || '';
    return this._language || this.defaultLanguage;
  }
  public set language(lang: string) {
    this._language = lang;
    // Store selected language
    //this.storage.set(StorageKey.SELECTED_LANG, lang);
    // Change index.html lang attr
    document.documentElement.lang = lang;
    // Emit new value to subscriptions
    this._lang.next(lang);
    // Change @ngx-translate language
    this.translate.use(this.language);
  }

  // tslint:disable-next-line: variable-name
  private _locale: string;
  get locale(): string {
    const storedLocale = this.storage.get(StorageKey.SELECTED_LOCALE);
    return this._locale || (storedLocale || this.language);
  }
  set locale(locale: string) {
    this._locale = locale;
    this.storage.set(StorageKey.SELECTED_LOCALE, locale); // Store selected locale
  }

  constructor(
    private translate: TranslateService,
    private storage: StorageService,
    private actionSheetController: ActionSheetController
  ) {
    this.translate.addLangs(environment.availableLanguages);
  }

  /**
   * Initialize app language loagin the selected language in this order:
   * 1. Stored lang (if it exists previously)
   * 2. Browser lang
   * 3. Default lang
   */
  public initLanguage() {
    this.translate.addLangs(environment.availableLanguages);
    this.translate.defaultLang = this.defaultLanguage;
    // Get Browser default language, as fallback
    this.browserLang = this.translate.getBrowserLang();
    // Get stored language
    // ANULADO POR AHORA
    const storedLang = null; //this.storage.get(StorageKey.SELECTED_LANG);
    const selectedLang = storedLang || this.browserLang;
    // Set stored lang or browser lang as fallback
    // If any of them is one of accepted langs, use default lang
    this.language = this.isAcceptedLang(selectedLang) ? selectedLang : this.translate.defaultLang;
  }

  public async showAvailableLangs() {
    const langActionSheet = await this.actionSheetController.create({
      header: this.translate.instant('LANGUAGE.SELECT_LANG'),
      // cssClass: 'my-custom-class',
      mode: 'md',
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant('LANGUAGE.eu'),
          handler: () => {
            this.changeLanguage('eu')
          }
        },
        {
          text: this.translate.instant('LANGUAGE.es'),
          handler: () => {
            this.changeLanguage('es')
          }
        },
        {
          text: this.translate.instant('LANGUAGE.en'),
          handler: () => {
            this.changeLanguage('en')
          }
        },
        {
          text: this.translate.instant('LANGUAGE.fr'),
          handler: () => {
            this.changeLanguage('fr')
          }
        },
        {
          text: this.translate.instant('LANGUAGE.it'),
          handler: () => {
            this.changeLanguage('it')
          }
        },
        {
          text: this.translate.instant('LANGUAGE.pt'),
          handler: () => {
            this.changeLanguage('pt')
          }
        },
      ]
    });
    // Create each langauge button
    /* this.translate.langs.forEach((lang: string) => {
      langActionSheet.buttons.push({
        text: this.translate.instant(`LANGUAGE.${lang}`),
        handler: () => {
          // this.changeLanguage(lang)
        }
      })
    }) */
    await langActionSheet.present();
    // const selection: OverlayEventDetail<{ data: any, role: string }> = await langActionSheet.onDidDismiss();
  }

  public changeLanguage(lang: string) {
    if (!this.isAcceptedLang(lang)) {
      return false;
    }
    this.language = lang;
    this.translate.use(lang); // Change translations language
    this.storage.set(StorageKey.SELECTED_LANG, lang);
    // this.changeLocale(lang);
  }

  /** Changes the app LOCALE and register new Culture */
  public changeLocale(locale: string) {
    // this.locale = locale;
    // CHANGE: doesn't work; changes the locale but the template doesn't update
    // setTimeout(() => {
    // registerLocaleData(localeFile);
    // Store selected locale & reload app
    this.storage.set(StorageKey.SELECTED_LOCALE, locale);
    window.location.reload();
    // }, 1000);
  }

  /* public registerLocale() {
    const localeData = Locale[this.locale] || Locale[this.defaultLanguage];
    registerLocaleData(localeData);
    this.storage.set(StorageKey.SELECTED_LOCALE, this.locale);
    return this.locale;
  } */

  /**
   * Devuelve el índice del mes (en el año) en base a la abreviatura, en el locale mandando
   * Si no se ajusta un locale, se usa por defecto el de la aplicación
   */
  public getMonthIndexByAbbr(monthAbbr: string, locale: string = this.language): number {
    const months = MONTHS[locale];
    return months.findIndex((month: string) => month === monthAbbr.toUpperCase());
  }

  /**
   * Devuelve la abreviatura del mes en el locale seleccionado
   * Si no se ajusta un locale, se usa por defecto el de la aplicación
   */
  public getMonthAbbrByIndex(monthIndex: number, locale: string = this.language): string {
    const meses = MONTHS[locale];
    return meses[monthIndex];
  }

  /* Check if language is included in app registered languages */
  private isAcceptedLang(lang: string) {
    return this.translate.getLangs().includes(lang);
  }
}
