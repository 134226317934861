/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Opciones posibles para la cantidad de una medicina
 */
export type Dosis = 1 | 0.5 | 0.25 | 0.125 | 0.33 | 0.66 | 0.75;

export const Dosis = {
    NUMBER_1: 1 as Dosis,
    NUMBER_0_DOT_5: 0.5 as Dosis,
    NUMBER_0_DOT_25: 0.25 as Dosis,
    NUMBER_0_DOT_125: 0.125 as Dosis,
    NUMBER_0_DOT_33: 0.33 as Dosis,
    NUMBER_0_DOT_66: 0.66 as Dosis,
    NUMBER_0_DOT_75: 0.75 as Dosis
};

