/**
 * CircuPack API
 * Rest API para acceder al sistema CircuPack
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelError { 
    timestamp?: string;
    status?: number;
    error?: string;
    message?: string;
}

