import { Component } from '@angular/core';
import { LanguageService } from '@core/services/language/language.service';
import { ThemeService } from '@core/services/theme.service';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { ViewChild } from '@angular/core';
import { ModalService } from '@core/services/modal/modal.service';
import { DeviceService } from '@core/services/device/device.service';
import { PwaService } from '@core/services/pwa/pwa.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  private exitConfirm: boolean = false;

  constructor(
    private platform: Platform,
    private language: LanguageService,
    private theme: ThemeService,
    private translate: TranslateService,
    private modalService: ModalService,
    private deviceService: DeviceService, // WARNING: Don't delete this service -> Init Device Info with priority
    private pwaService: PwaService,
  ) {
    this.platform.ready().then(() => {
      this.initializeApp();
    });
  }

  private initializeApp() {
    // Listen PWA Install prompt event
    this.pwaService.listenInstallPrompt();
    // Init app language (and LOCALE)
    this.language.initLanguage();
    // Init theme (Light/Dark mode)
    this.theme.initTheme();
    // Subscribe to device back button
    // this.deviceBackButton(); CHANGE: Es PWA
    SplashScreen.hide();
  }

  /** Subscribe to device back button (Android) */
  private deviceBackButton() {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      // If it's at root page
      if (!this.routerOutlet.canGoBack()) {
        // And previously try to exit app
        if (this.exitConfirm) {
          // Exit app
          navigator['app'].exitApp();
        } else {  // If it's first attempt
          // Show an info snackbar with instructions
          const exitConfirmation: string = this.translate.instant('APP.TAP_EXIT_AGAIN');
          this.modalService.showSnackbar(exitConfirmation);
          // Activate first attempt
          this.exitConfirm = true;
          // Disable after 30 sec
          setTimeout(() => {
            this.exitConfirm = false;
          }, (30 * 1000));
        }
      }
    });
  }


}
